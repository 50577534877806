<template>
    <div class="header vw-100 mb-5">
        <div
                class="holder"
                :class="{
        blur: !hqLoaded
      }"
                ref="head"
                :style="{ backgroundImage: 'url(' + image + ')' }"
        >

        </div>
        <img
                class="lazy-hq"
                :src="imageHQ"
                alt="placeholder image"
                @load="loadHQ"
        />
        <div class="container w-100 h-100">
            <div class="row align-items-center w-100 h-100">
                <div class="title">
                    <h1 class="text-white">
                        {{title}}
                        <template v-if="subtitle">
                            <br/>
                            <small class="">{{subtitle}}</small>
                        </template>

                    </h1>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "HeaderComponent",
        props: {
            imageHQ: {},
            imageLQ: {},
            title: {},
            subtitle: {}
        },
        methods: {
            loadHQ(event) {
                this.image = event.target.src;
                this.hqLoaded = true;
                console.log("loadHQ called!", this.image, this.hqLoaded);

            }
        },
        data() {
            return {
                image: "",
                hqLoaded: false
            };
        },
        mounted() {
            this.image = this.imageLQ;
        }
    };
</script>
<style lang="scss">
    .header {
        height: 50vh;
        width: 100vw;

        .holder {
            display: block;
            height: 50vh;
            width: 100vw;
            background-image: unset;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center bottom;
            position: absolute;
            z-index: -1;
            /*filter: grayscale(100%);*/
            &.blur {
                filter: blur(8px);
                /*-webkit-filter: blur(8px);*/
                > * {
                    display: none;
                }
            }
        }
    }

    .title {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 25px;
        border-radius: 5px;
    }

    .lazy-hq {
        display: none;
    }
</style>

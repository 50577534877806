<template>
  <div class="page">
    <HeaderComponent
      image-h-q="https://picsum.photos/id/765/240/135"
      image-l-q="https://picsum.photos/id/765/240/135"
      title="Building this website"
      subtitle="A tale of VueJS, Cloudflare Workers and more"
    />
    <div class="container">
      <span class="first-char">I</span> spent the last few weeks thinking about this website, and how I was planning to develop it<br>
      Everything breaks
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderComponent from "./HeaderComponent";

export default {
  name: "Post",
  components: {
    HeaderComponent,
  }
};
</script>
<style lang="scss">
.splash {
  width: 100vw;
}
  .first-char{
    font-size: 4em;
  }
</style>
